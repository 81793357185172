<template>
  <div class="mt-1" v-if="canVerb(resource, 'index')">
    <b-row>
      <b-col align="left">
        <b-button-group
          v-b-toggle:collapse-1
          class="my-2 filter-button-group"
          :title="getDefaultFilterMessage()"
        >
          <span class="when-open">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          <span class="when-closed">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          Filtra
        </b-button-group>
      </b-col>
      <b-col align="right">
        <b-button
          class="btn-create"
          type="button"
          variant="primary"
          size="sm"
          @click="onAddBookEntry"
          title="Crea"
          v-if="canVerb(resource, 'store')"
          ><b-icon icon="plus-circle" aria-hidden="true"></b-icon>
          Crea</b-button
        >
      </b-col>
    </b-row>

    <b-collapse visible id="collapse-1">
      <b-form @submit.prevent="onSearch(filterName)">
        <b-card class="filter">
          <b-row>
            <div class="col-md-3">
              <base-select
                name="Produttore"
                label="Produttore"
                v-model="filter.byBroker.id"
                :options="salesmen"
                :taggable="salesmen_taggable"
                :multiple="true"
                :closeOnSelect="false"
                :defaultHtmlMultipleSelect="true"
                @changeTaggable="changeTaggable"
              />
            </div>
            <div class="col-md-3">
              <base-datepicker
                name="book_date"
                label="Data Registrazione Dal"
                v-model="filter.byCalendar.from"
              />
            </div>
            <div class="col-md-3">
              <base-datepicker
                name="book_date"
                label="Data Registrazione al"
                v-model="filter.byCalendar.to"
              />
            </div>
          </b-row>
          <b-row>
            <div class="form-group col-md-3 align-self-end">
              <b-button
                type="submit"
                variant="lisaweb"
                size="sm"
                v-b-toggle:collapse-1
                >Cerca</b-button
              >
              <b-button
                class="btn-reset"
                type="button"
                variant="lisaweb"
                size="sm"
                @click="onClearFilter(filterName)"
                >Reset</b-button
              >
            </div>
          </b-row>
        </b-card>
      </b-form>
    </b-collapse>

    <!-- <button-group-table></button-group-table> -->

    <salesmen-book-entry
      @edit="onEdit"
      @destroy="onDestroy"
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterOn="{
        byRelations: [
          'byBroker',
          'byDocument',
          'byTask',
          'byRegistry',
          'byInsurer',
          'byInsuranceAncillary',
          'byVariousAccounting',
          'byBookTag',
        ],
      }"
      :filterName="filterName"
      :ref="tableRef"
      :onlyActions="['destroy', 'edit']"
    ></salesmen-book-entry>
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
import SalesmenBookEntry from "@/components/tables/SalesmenBookEntry";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
// import ButtonGroupTable from "@/components/ButtonGroupTable";
import BaseSelect from "@/components/form/BaseSelect";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import { mapGetters, mapActions } from "vuex";
import { toLocaleDate } from "@/utils/dates";
import { toLocaleCurrency } from "@/utils/strings";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";

export default {
  mixins: [CustomFiltersMixin],
  data() {
    return {
      filter: this.initFilter(),
      filterName: "filterSalesmenBookEntry",
      resource: "book_entries",
      repository: "book_entry",
      tableRef: "SalesmenBookEntryTableRef",
      salesmen: [],
      salesmen_taggable: true,
      fields: [
        {
          key: "broker",
          label: this.getDictionary("broker", "book_entry"),
        },
        {
          key: "book_date",
          label: this.getDictionary("book_date", "book_entry"),
          formatter: (value) => toLocaleDate(value),
        },
        {
          key: "title",
          label: this.getDictionary("title", "book_entry"),
        },
        {
          key: "gross",
          label: "Premio",
          sortable: false,
          formatter: (value) => toLocaleCurrency(value),
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
          tdClass: "text-right",
        },
        {
          key: "net",
          label: this.getDictionary("net", "book_entry"),
          sortable: false,
          formatter: (value) => toLocaleCurrency(value),
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
          tdClass: "text-right",
        },
        {
          key: "saler_prov_take",
          label: "Provv.Acq.Prod",
          sortable: false,
          formatter: (value) => toLocaleCurrency(value),
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
          tdClass: "text-right",
        },
      ],
    };
  },
  components: {
    // ButtonGroupTable,
    BaseSelect,
    BaseDatepicker,
    SalesmenBookEntry,
  },
  methods: {
    changeTaggable(val) {
      this.salesmen_taggable = val;
    },
    initFilter() {
      let init = {
        byBroker: {
          id: null,
        },
        byCalendar: {
          from: null,
          to: null,
        },
        byVariousAccounting: {
          id: 2,
        },
      };
      return init;
    },
    onAddBookEntry() {
      // have to open the "salesmen" variant of book_entries create
      this.$router.push({
        name: `book_entries.create`,
        params: { mode: "II" },
      });
    },
    onEdit(item) {
      this.$router.push({
        name: `${this.resource}.edit`,
        params: { id: `${item.id}`, item: item, mode: "II" },
      });
    },
    onDestroy(id) {
      const Repo = RepositoryFactory.get(this.repository);
      Repo.destroy(id)
        .then(() => {
          // console.debug("successfully deleted!");
          this.$refs[this.tableRef].fetch();
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    onSearch(name) {
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      this.$refs[this.tableRef].fetch();
    },
    onClearFilter(name) {
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
      // this.onSearch(name);
    },
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },
    customFilterName(key, prefix = "byAttribute") {
      return `${prefix}.${key}`;
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    ...mapGetters("auth", {
      getSalesmen: "salesmen",
    }),
  },
  beforeMount() {
    this.salesmen = this.getSalesmen();
  },
};
</script>
